import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/company";
const subUrllocal1 = "/api/v1/user";
const subUrllocal2 = "/api/v1/licensePlan";
const subUrllocal3 = "/api/v1/dataManager";

class companyService {
  getCompanyAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/companies`, data, headers);
  createCompanyAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  updateCompanyyAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/update`, data, headers);
  deleteCompanyApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  getCountryTimeZoneAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/time-zone`, data, headers);
  applyLicencingtocountryAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal2}/assignLicensetoCompany`,
      data,
      headers
    );
  getCompanyRelatedFieldsAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal3}/getData`,
      data,
      headers
    );
  setFieldCanbeUsedInHistoricalAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal3}/assignDataToCompany`,
      data,
      headers
    );
}
// eslint-disable-next-line
export default new companyService();
