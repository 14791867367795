import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyServices from "../../Services/companyServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getLicencingData } from "../Licensing/Licences.Action";
import DatamanagerServices from "../../Services/DatamanagerServices";
export const getCompanydetails = createAsyncThunk(
  "Company/getCompanydetails",
  async (data, thunkAPI) => {
    try {
      let res = await CompanyServices.getCompanyAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createCompany = createAsyncThunk(
  "Company/createCompany",
  async ({ data, companyPagination }, thunkAPI) => {
    try {
      const res = await CompanyServices.createCompanyAPI(data);
      thunkAPI.dispatch(getCompanydetails(companyPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateCompany = createAsyncThunk(
  "Company/updateCompany",
  async ({ data, companyPagination }, thunkAPI) => {
    try {
      const res = await CompanyServices.createCompanyAPI(data);
      thunkAPI.dispatch(getCompanydetails(companyPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteCompany = createAsyncThunk(
  "Company/deleteCompany",
  async ({ data, companyPagination }, thunkAPI) => {
    try {
      const res = await CompanyServices.deleteCompanyApi(data);
      thunkAPI.dispatch(getCompanydetails(companyPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatepremiumfeaturesCompany = createAsyncThunk(
  "Company/updatepremiumfeaturesCompany",
  async ({ data, companyPagination }, thunkAPI) => {
    try {
      const res = await CompanyServices.createCompanyAPI(data);
      thunkAPI.dispatch(getCompanydetails(companyPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCountryTimeZone = createAsyncThunk(
  "Company/getCountryTimeZone",
  async (data, thunkAPI) => {
    try {
      let res = await CompanyServices.getCountryTimeZoneAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const applyLicencingtocountry = createAsyncThunk(
  "Company/applyLicencingtocountry",
  async ({ data, companyPagination }, thunkAPI) => {
    try {
      let res = await CompanyServices.applyLicencingtocountryAPI(data);
      thunkAPI.dispatch(getCompanydetails(companyPagination));
      thunkAPI.dispatch(
        getLicencingData({
          orderBy: "createdAt",

          sortBy: "DESC",
        })
      );

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCompanyRelatedFields = createAsyncThunk(
  "Company/getCompanyRelatedFields",
  async (data, thunkAPI) => {
    try {
      let res = await CompanyServices.getCompanyRelatedFieldsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setFieldCanbeUsedInHistorical = createAsyncThunk(
  "Company/setFieldCanbeUsedInHistorical",
  async (data, thunkAPI) => {
    try {
      let res = await CompanyServices.setFieldCanbeUsedInHistoricalAPI(data);
      // thunkAPI.dispatch(
      //   getCompanyRelatedFields({
      //     companyId: data.companyId,
      //   })
      // );
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const DeleteDataField = createAsyncThunk(
  "Company/DeleteDataField",
  async ({ data, CompanyFieldListPagination }, thunkAPI) => {
    try {
      let res = await DatamanagerServices.DeleteDataFieldAPI(data);
      thunkAPI.dispatch(getCompanyRelatedFields(CompanyFieldListPagination));

      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
