export const TodayChat = [
  {
    id: 1,
    name: "John Doe",
    message: "What is MSA contract?",
    time: "10:00 AM",
    isRead: false,
  },
  {
    id: 2,
    name: "John Doe",
    message: "What is SOW contract?",
    time: "10:00 AM",
    isRead: false,
  },
  {
    id: 1,
    name: "John Doe",
    message: "What is MSA contract?",
    time: "10:00 AM",
    isRead: false,
  },
  {
    id: 2,
    name: "John Doe",
    message: "What is SOW contract?",
    time: "10:00 AM",
    isRead: false,
  },
  {
    id: 1,
    name: "John Doe",
    message: "What is MSA contract?",
    time: "10:00 AM",
    isRead: false,
  },
  {
    id: 2,
    name: "John Doe",
    message: "What is SOW contract?",
    time: "10:00 AM",
    isRead: false,
  },
];
export const tabledatasource = [
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What is the compensation for the services?",
    answer:
      "$20,000.00 as a flat fee. Invoices due within 30 days after services are complete.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "Who owns the intellectual property?",
    answer:
      "The Contractor owns all intellectual property developed, and the Client is granted a non-exclusive limited-use license.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },
  {
    key: "1",
    question: "What services are being provided?",
    answer:
      "Design and fabricate a free-standing tool rack for showroom display.",
    impact: "High",
    risk: "Scope creep or disputes regarding deliverables.",
    source: `Section 1, "Services Provided"`,
  },

 
];
