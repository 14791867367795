import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanydetails,
  createCompany,
  updateCompany,
  deleteCompany,
  updatepremiumfeaturesCompany,
  getCountryTimeZone,
  applyLicencingtocountry,
  getCompanyRelatedFields,
  setFieldCanbeUsedInHistorical,
} from "./company.action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  companyTableData: [],
  isVisible: false,
  editRecord: {},
  type: "",
  companymDetails: {},
  totalCount: null,
  companyPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  defaultcompanyPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  isLicencedrawer: false,
  dataforfeatures: {},
  istimeZoneLoading: false,
  companyTimeZoneData: [],
  islicenceapplyLoading: false,
  issigndocWithModalVisible: false,
  filterpopovervisible: false,
  isfilterActive: false,
  isSettingDrawerVisible: false,
  CompanyFieldsList: [],
  CompanyFieldsListLoading: false,
  settingField: false,
  CompanyFieldListPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
};
const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.companyTimeZoneData = [];
      }
    },
    onPageChangesFilter: (state, action) => {
      state.companyPagination = {
        ...state.companyPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.companyPagination = {
        ...state.companyPagination,
        search: action.payload.search || "",
      };
    },
    companydatareset: (state, action) => {
      return initialState;
    },
    showLicencedrawer: (state, { payload }) => {
      state.isLicencedrawer = payload.visible;
      state.dataforfeatures = payload.record;
    },
    signdocWithModalVisible: (state, { payload }) => {
      state.issigndocWithModalVisible = payload.visible;
      // state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.companyTimeZoneData = [];
      }
    },
    filterpopoverhandleOpenChange: (state, { payload }) => {
      state.filterpopovervisible = payload;
    },
    setfilterActive: (state, { payload }) => {
      state.isfilterActive = payload;
    },
    filterchangepagination: (state, { payload }) => {
      if (Object.keys(payload).length === 0) {
        state.companyPagination = {
          ...state.defaultcompanyPagination,
        };
      } else {
        state.companyPagination = {
          ...state.companyPagination,
          ...payload,
        };
      }
    },
    setSettingDrawerVisible: (state, { payload }) => {
      state.isSettingDrawerVisible = payload?.visible;
      state.dataforfeatures = payload.record;
    },
    onFieldPageChangesFilter: (state, action) => {
      state.CompanyFieldListPagination = {
        ...state.CompanyFieldListPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanydetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanydetails.fulfilled, (state, { payload }) => {
        state.companyTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.companyPagination = {
          ...state.companyPagination,
          total: payload?.total ? payload?.total : 0,
        };
        if (state.isLicencedrawer === true) {
          state.dataforfeatures = payload.data.find(
            (ele) => ele._id === state.dataforfeatures._id
          );
        }
      })
      .addCase(getCompanydetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createCompany.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createCompany.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createCompany.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updateCompany.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(updateCompany.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(deleteCompany.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(deleteCompany.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updatepremiumfeaturesCompany.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatepremiumfeaturesCompany.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isLicencedrawer = false;
      })
      .addCase(updatepremiumfeaturesCompany.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(getCountryTimeZone.pending, (state) => {
        state.istimeZoneLoading = true;
      })
      .addCase(getCountryTimeZone.fulfilled, (state, { payload }) => {
        state.companyTimeZoneData = payload.data;
        state.istimeZoneLoading = false;
      })
      .addCase(getCountryTimeZone.rejected, (state, { payload }) => {
        state.istimeZoneLoading = false;
      })
      .addCase(applyLicencingtocountry.pending, (state) => {
        state.islicenceapplyLoading = true;
      })
      .addCase(applyLicencingtocountry.fulfilled, (state, { payload }) => {
        state.islicenceapplyLoading = false;
      })
      .addCase(applyLicencingtocountry.rejected, (state, { payload }) => {
        state.islicenceapplyLoading = false;
      })
      .addCase(getCompanyRelatedFields.pending, (state) => {
        state.CompanyFieldsListLoading = true;
      })
      .addCase(getCompanyRelatedFields.fulfilled, (state, { payload }) => {
        state.CompanyFieldsList = payload?.data;
        state.CompanyFieldsListLoading = false;
        // state.totalCount = payload.total;
        state.CompanyFieldListPagination = {
          ...state.CompanyFieldListPagination,
          total: payload?.total ? payload?.total : 0,
        };
      })
      .addCase(getCompanyRelatedFields.rejected, (state, { payload }) => {
        state.CompanyFieldsListLoading = false;
      })
      .addCase(setFieldCanbeUsedInHistorical.pending, (state) => {
        state.settingField = true;
      })
      .addCase(
        setFieldCanbeUsedInHistorical.fulfilled,
        (state, { payload }) => {
          state.settingField = false;
          state.CompanyFieldsList = payload?.data;
        }
      )
      .addCase(setFieldCanbeUsedInHistorical.rejected, (state, { payload }) => {
        state.settingField = false;
      });
  },
});
export const {
  setModalVisible,
  companydatareset,
  onPageChangesFilter,
  onSearchList,
  showLicencedrawer,
  signdocWithModalVisible,
  filterpopoverhandleOpenChange,
  setfilterActive,
  filterchangepagination,
  setSettingDrawerVisible,
  onFieldPageChangesFilter,
} = companySlice.actions;

export default companySlice.reducer;
