import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { aicreatedData } from "../Content/Drawer/aidatahelper";
import { TodayChat, tabledatasource } from "./AIhelper";

import {
  ShareAltOutlined,
  SendOutlined,
  PaperClipOutlined,
  GlobalOutlined,
  CopyOutlined,
  ToolOutlined,
  ArrowsAltOutlined,
  FileTextOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EditOutlined,
} from "@ant-design/icons";
import "./index.less";
import { connect, useDispatch } from "react-redux";
import { getContent } from "../Content/content.Action";
import { injectIntl } from "react-intl";
const { Header, Sider, Content } = Layout;

const Index = (props) => {
  const {
    content,
    intl: { formatMessage },
  } = props;
  const {
    contentTableData = [],
    isLoading = false,
    contentPagination,
    isVisible,
    isContentVisible,
  } = content;
  const textAreaRef = useRef(null);
  const [form] = Form.useForm();
  const [prompt, setprompt] = useState("");
  const [record, setrecord] = useState({});
  const [view, setview] = useState("prompt");
  const [mode, setmode] = useState("editor");
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContent(contentPagination)).then((res) => {
      console.log(res.payload?.data[13]);
      setrecord(res.payload?.data[13]);
    });
  }, []);
  const sendPromptoAI = async () => {
    const values = await form.validateFields();
    setprompt(values?.prompt);
    // setCollapsed(true);
    setview("id");
  };
  return (
    <Layout>
      <Sider
        collapsedWidth={0}
        collapsed={collapsed}
        width={250}
        trigger={null}
        collapsible
      >
        <div className="AI-sider">
          <div className="AI-sider-header">
            <div className="Ai-sider-header-title">Ask AI</div>
          </div>
          <div className="AI-sider-body">
            <Input.Search />
            <div className="chat-action-btn-container">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Button
                    onClick={() => {
                      setview("prompt");
                    }}
                    block
                    icon={<EditOutlined />}
                  >
                    Start New
                  </Button>
                </Col>
                <Col span={24}>
                  <Button block>Explore More...</Button>
                </Col>
              </Row>
            </div>
            <div className="chat-container-main">
              <div className="chat-container">
                <div className="chat-title">Today Chat</div>
                <div>
                  {TodayChat?.map((eve, eveIndex) => (
                    <div
                      onClick={() => {
                        setview("id");
                      }}
                      className="chat-item-text"
                      key={eveIndex}
                    >
                      <div>{eve?.message}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="chat-container">
                <div className="chat-title">Yesterday Chat</div>
                <div>
                  {TodayChat?.map((eve, eveIndex) => (
                    <div
                      onClick={() => {
                        setview("id");
                      }}
                      className="chat-item-text"
                      key={eveIndex}
                    >
                      <div>{eve?.message}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="chat-container">
                <div className="chat-title">Previous 30 Chat</div>
                <div>
                  {TodayChat?.map((eve, eveIndex) => (
                    <div
                      onClick={() => {
                        setview("id");
                      }}
                      className="chat-item-text"
                      key={eveIndex}
                    >
                      <div>{eve?.message}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        {/* <Header>

        </Header>       */}
        <Content>
          {view === "prompt" && (
            <Row>
              <Col span={24}>
                <div className="AI-header">
                  <div className="AI-header-title">
                    <div>
                      {" "}
                      <Button
                        type="text"
                        icon={
                          collapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                      />
                    </div>
                    <div>ASK AI</div>
                  </div>
                  <div>
                    <div>
                      <Button
                        type="text"
                        icon={<ShareAltOutlined />}
                        size="middle"
                      >
                        share
                      </Button>
                      &nbsp;
                      <Avatar>HV</Avatar>
                    </div>
                  </div>
                </div>
              </Col>
              <Col offset={5} span={14}>
                <div className="AI-chat-container">
                  <div className="AI-greeting-text">What can I help with?</div>
                </div>
              </Col>
              <Col offset={5} span={14}>
                <div className="Input_container_box">
                  <div>
                    <Form form={form}>
                      <Form.Item name={"prompt"}>
                        <Input.TextArea
                          ref={textAreaRef}
                          placeholder="Create with AI..."
                          autoSize={{
                            minRows: 1,
                            maxRows: 6,
                          }}
                          bordered={false}
                        ></Input.TextArea>
                      </Form.Item>
                    </Form>
                  </div>
                  <div>
                    <Row>
                      <Col span={18}>
                        <div className="extra-tools-btn">
                          <Tooltip title={"Attach files"}>
                            <Button
                              type="text"
                              icon={<PaperClipOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title={"Web"}>
                            <Button
                              type="text"
                              icon={<GlobalOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title={"Tools"}>
                            <Button
                              type="text"
                              icon={<ToolOutlined />}
                            ></Button>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col offset={4} span={2}>
                        <Button
                          onClick={() => {
                            sendPromptoAI();
                          }}
                          type="text"
                          icon={<SendOutlined />}
                        ></Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {view === "id" && (
            <Row>
              <Col span={24}>
                <div className="AI-header">
                  <div className="AI-header-title">
                    <div>
                      {" "}
                      <Button
                        type="text"
                        icon={
                          collapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                      />
                    </div>
                    <div>ASK AI</div>
                  </div>
                  <div>
                    <div>
                      <Button
                        type="text"
                        icon={<ShareAltOutlined />}
                        size="middle"
                      >
                        share
                      </Button>
                      &nbsp;
                      <Avatar>HV</Avatar>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="AI-chatbox-container-main">
                  {aicreatedData?.map((eve, eveIndex) => (
                    <>
                      {eve?.typeBy === "me" && (
                        <div className="user_prompt_container ">
                          <div className="user_prompt main-user-prompt-box">
                            {eve?.prompt}
                          </div>
                        </div>
                      )}
                      {eve?.typeBy !== "me" && (
                        <div className="AI_prompt_container">
                          <div>
                            <Avatar>AI</Avatar>
                          </div>

                          <div className="Ai_response">
                            <div>
                              <Card
                                style={{
                                  width: "80%",
                                  borderRadius: "10px",
                                }}
                                bodyStyle={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                                hoverable
                                extra={
                                  <>
                                    <Tooltip title="Extract to Editor">
                                      <Button
                                        onClick={() => {
                                          setview("editor");
                                          setCollapsed(true);
                                        }}
                                        type="text"
                                        icon={<ArrowsAltOutlined />}
                                        size="small"
                                      ></Button>
                                    </Tooltip>{" "}
                                    <Tooltip title="copy">
                                      <Button
                                        type="text"
                                        icon={<CopyOutlined />}
                                        size="small"
                                      ></Button>
                                    </Tooltip>
                                  </>
                                }
                                size="small"
                                title={eve?.response}
                              >
                                <div
                                  // className="modal-content"
                                  dangerouslySetInnerHTML={{
                                    __html: record?.content,
                                  }}
                                />
                              </Card>
                            </div>
                            <div className="Ai_response_text">
                              {eve?.responseContent}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  <div className="user_prompt_container ">
                    <div className="user_prompt main-user-prompt-box">
                      {"what is risk regarding this contract"}
                    </div>
                  </div>
                  <div className="AI_prompt_container">
                    <div>
                      <Avatar>AI</Avatar>
                    </div>

                    <div className="Ai_response">
                      <div></div>
                      <div className="Ai_response_text">
                        {
                          <Table
                            bordered
                            style={{
                              borderRadius: "8px",
                            }}
                            title={() => (
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                <Tooltip title="Extract to Editor">
                                  <Button
                                    onClick={() => {
                                      setview("editor");
                                      setCollapsed(true);
                                      setmode("table");
                                    }}
                                    type="text"
                                    icon={<ArrowsAltOutlined />}
                                    size="small"
                                  ></Button>
                                </Tooltip>
                              </div>
                            )}
                            size="small"
                            pagination={false}
                            dataSource={tabledatasource}
                            columns={[
                              {
                                title: "Question",
                                dataIndex: "question",
                                key: "question",
                              },
                              {
                                title: "Answer",
                                dataIndex: "answer",
                                key: "answer",
                              },
                              {
                                title: "Sources",
                                dataIndex: "source",
                                key: "source",
                              },
                              {
                                title: "Risk",
                                dataIndex: "risk",
                                key: "risk",
                              },
                            ]}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col offset={5} span={14}>
                <div className="Input_container_box">
                  <div>
                    <Form form={form}>
                      <Form.Item name={"prompt"}>
                        <Input.TextArea
                          ref={textAreaRef}
                          placeholder="Create with AI..."
                          autoSize={{
                            minRows: 1,
                            maxRows: 6,
                          }}
                          bordered={false}
                        ></Input.TextArea>
                      </Form.Item>
                    </Form>
                  </div>
                  <div>
                    <Row>
                      <Col span={18}>
                        <div className="extra-tools-btn">
                          <Tooltip title={"Attach files"}>
                            <Button
                              type="text"
                              icon={<PaperClipOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title={"Web"}>
                            <Button
                              type="text"
                              icon={<GlobalOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title={"Tools"}>
                            <Button
                              type="text"
                              icon={<ToolOutlined />}
                            ></Button>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col offset={4} span={2}>
                        <Button
                          onClick={() => {
                            sendPromptoAI();
                          }}
                          type="text"
                          icon={<SendOutlined />}
                        ></Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            {view === "editor" && (
              <Col span={6}>
                <div className="AI-sider-for-response">
                  <Row>
                    <Col span={24}>
                      <div className="AI-sider-for-response-header">
                        <Avatar>AI</Avatar>
                        {/* <Button size="small" icon={<ShareAltOutlined />}>
                          Share
                        </Button> */}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="AI-chatbox-container">
                        {aicreatedData?.map((eve, eveIndex) => (
                          <>
                            {eve?.typeBy === "me" && (
                              <div className="user_prompt_container">
                                <div className="user_prompt">{eve?.prompt}</div>
                              </div>
                            )}
                            {eve?.typeBy !== "me" && (
                              <div className="AI_prompt_container">
                                <div>
                                  <Avatar>AI</Avatar>
                                </div>

                                <div className="Ai_response">
                                  <div>
                                    <Button
                                      className="response-btn"
                                      icon={<FileTextOutlined />}
                                    >
                                      {eve?.response}
                                    </Button>
                                  </div>
                                  <div className="Ai_response_text">
                                    {eve?.responseContent}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                        <div className="user_prompt_container">
                          <div className="user_prompt">
                            {"What is risk analysis according to contract"}
                          </div>
                        </div>
                        <div className="AI_prompt_container">
                          <div>
                            <Avatar>AI</Avatar>
                          </div>

                          <div className="Ai_response">
                            <div>
                              <Button
                                className="response-btn"
                                icon={<FileTextOutlined />}
                              >
                                Risk analysis report
                              </Button>
                            </div>
                            <div className="Ai_response_text">
                              The draft for the Master Services Agreement has
                              been created. Let me know if you need any
                              adjustments or additional clauses.
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col offset={1} span={22}>
                      <div className="Input_container_box">
                        <div>
                          <Form form={form}>
                            <Form.Item name={"prompt"}>
                              <Input.TextArea
                                ref={textAreaRef}
                                placeholder="Create with AI..."
                                autoSize={{
                                  minRows: 1,
                                  maxRows: 6,
                                }}
                                bordered={false}
                              ></Input.TextArea>
                            </Form.Item>
                          </Form>
                        </div>
                        <div>
                          <Row>
                            <Col span={18}>
                              <div className="extra-tools-btn">
                                <Tooltip title={"Attach files"}>
                                  <Button
                                    type="text"
                                    icon={<PaperClipOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Web"}>
                                  <Button
                                    type="text"
                                    icon={<GlobalOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Tools"}>
                                  <Button
                                    type="text"
                                    icon={<ToolOutlined />}
                                  ></Button>
                                </Tooltip>
                              </div>
                            </Col>
                            <Col offset={4} span={2}>
                              <Button
                                onClick={() => {
                                  sendPromptoAI();
                                }}
                                type="text"
                                icon={<SendOutlined />}
                              ></Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}

            {view === "editor" && (
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <div className="content-AI-header">
                      <div className="content-AI-header-left">
                        <Button
                          onClick={() => {
                            setview("prompt");
                            setCollapsed(false);
                          }}
                          icon={<CloseOutlined />}
                          type="text"
                        ></Button>
                        &nbsp;
                        <div className="AI-response-title">
                          {mode === "editor"
                            ? "MSA Contract Draft"
                            : "Risk analysis report"}
                        </div>
                      </div>
                      <div>
                        <div className="AI-header-extraAction">
                          <Tooltip title="copy">
                            <CopyOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="content-AI">
                      {mode === "editor" ? (
                        <div
                          // className="modal-content"
                          dangerouslySetInnerHTML={{
                            __html: record?.content,
                          }}
                        />
                      ) : (
                        <Table
                          bordered
                          style={{
                            borderRadius: "8px",
                          }}
                          title={() =>
                            mode === "editor" ? (
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                <Tooltip title="Extract to Editor">
                                  <Button
                                    onClick={() => {
                                      setview("editor");
                                      setCollapsed(true);
                                    }}
                                    type="text"
                                    icon={<ArrowsAltOutlined />}
                                    size="small"
                                  ></Button>
                                </Tooltip>
                              </div>
                            ) : (
                              ""
                            )
                          }
                          size="small"
                          pagination={false}
                          dataSource={tabledatasource}
                          columns={[
                            {
                              title: "Question",
                              dataIndex: "question",
                              key: "question",
                            },
                            {
                              title: "Answer",
                              dataIndex: "answer",
                              key: "answer",
                            },
                            {
                              title: "Sources",
                              dataIndex: "source",
                              key: "source",
                            },
                            {
                              title: "Risk",
                              dataIndex: "risk",
                              key: "risk",
                            },
                          ]}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default connect((state) => ({
  user: state.user,
  staff: state.staff,
  template: state.template,
  templateservice: state.templateservice,
  taskmanagement: state.taskmanagement,
  global: state.global,
  content: state.content,
  contentservice: state.contentservice,
  Obligation: state.Obligation,
}))(injectIntl(Index));
