import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseMessageHandler } from "../../globals/components/notification";
import DatamanagerServices from "../../Services/DatamanagerServices";
import { getCompanyRelatedFields } from "../Company/company.action";

export const getFieldsList = createAsyncThunk(
  "datamanager/getFieldsList",
  async (data, thunkAPI) => {
    try {
      let res = await DatamanagerServices.getFieldsListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreateandUpdateDataField = createAsyncThunk(
  "datamanager/CreateandUpdateDataField",
  async ({ data, datamangerPagination }, thunkAPI) => {
    try {
      let res = await DatamanagerServices.CreateandUpdateDataFieldAPI({
        ...data,
      });
      if (["editfromcompany","createfromcompany"].includes(data.type)) {
        thunkAPI.dispatch(
          getCompanyRelatedFields({
            companyId: data.companyId,
          })
        );
      } else {
        thunkAPI.dispatch(getFieldsList(datamangerPagination));
      }
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const DeleteDataField = createAsyncThunk(
  "datamanager/DeleteDataField",
  async ({ data, datamangerPagination }, thunkAPI) => {
    try {
      let res = await DatamanagerServices.DeleteDataFieldAPI(data);
      thunkAPI.dispatch(getFieldsList(datamangerPagination));

      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
