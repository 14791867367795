export const aicreatedData = [
  {
    id: 1,
    typeBy: "me",
    prompt: "Create a MSA Contract",
  },
  {
    id: 2,
    typeBy: "Ai",
    prompt: "Create a MSA Contract",
    response: "MSA Contract",
    created: "2022-01-01T00:00:00.000Z",
    responseContent:
      "The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses.",
  },
  {
    id: 3,
    typeBy: "me",
    prompt: "Create a SOW Contract",
  },
  {
    id: 4,
    typeBy: "Ai",
    prompt: "Create a SOW Contract",
    response: "SOW Contract",
    created: "2022-01-01T00:00:00.000Z",
   responseContent:"The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses."

  },
  {
    id: 1,
    typeBy: "me",
    prompt: "Create a MSA Contract",
  },
  {
    id: 2,
    typeBy: "Ai",
    prompt: "Create a MSA Contract",
    response: "MSA Contract",
    created: "2022-01-01T00:00:00.000Z",
    responseContent:
      "The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses.",
  },
  {
    id: 3,
    typeBy: "me",
    prompt: "Create a SOW Contract",
  },
  {
    id: 4,
    typeBy: "Ai",
    prompt: "Create a SOW Contract",
    response: "SOW Contract",
    created: "2022-01-01T00:00:00.000Z",
   responseContent:"The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses."

  },
  {
    id: 1,
    typeBy: "me",
    prompt: "Create a MSA Contract",
  },
  {
    id: 2,
    typeBy: "Ai",
    prompt: "Create a MSA Contract",
    response: "MSA Contract",
    created: "2022-01-01T00:00:00.000Z",
    responseContent:
      "The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses.",
  },
  {
    id: 3,
    typeBy: "me",
    prompt: "Create a SOW Contract",
  },
  {
    id: 4,
    typeBy: "Ai",
    prompt: "Create a SOW Contract",
    response: "SOW Contract",
    created: "2022-01-01T00:00:00.000Z",
   responseContent:"The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses."

  },
  {
    id: 1,
    typeBy: "me",
    prompt: "Create a MSA Contract",
  },
  {
    id: 2,
    typeBy: "Ai",
    prompt: "Create a MSA Contract",
    response: "MSA Contract",
    created: "2022-01-01T00:00:00.000Z",
    responseContent:
      "The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses.",
  },
  {
    id: 3,
    typeBy: "me",
    prompt: "Create a SOW Contract",
  },
  {
    id: 4,
    typeBy: "Ai",
    prompt: "Create a SOW Contract",
    response: "SOW Contract",
    created: "2022-01-01T00:00:00.000Z",
   responseContent:"The draft for the Master Services Agreement has been created. Let me know if you need any adjustments or additional clauses."

  },
];
