import { createSlice } from "@reduxjs/toolkit";
import { CreateandUpdateDataField, getFieldsList } from "./datamanager.Action";

const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,

  isVisible: false,

  editRecord: {},
  type: "",

  totalCount: null,
  datamangerPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  CurrentselectedItems: "parent",
  iscreateFieldDrawerVisible: false,
  FieldsList: [],
  drawerType: "",
};
const DatamanagerSlice = createSlice({
  name: "Datamanager",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.type = payload.type || "";
      state.editRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
        state.contentPreviewData = "";
      }
    },
    setselectedItems: (state, { payload }) => {
      state.CurrentselectedItems = payload.item;
    },
    setCreateFieldDrawerVisible: (state, { payload }) => {
      state.iscreateFieldDrawerVisible = payload.visible;
      state.drawerType = payload?.type || "";
      state.drawerRecord = payload?.record || {};
    },
    onSearchList: (state, { payload }) => {
      state.datamangerPagination = {
        ...state.datamangerPagination,
        search: payload.search || "",
      };
    },
    onPageChangesFilter: (state, { payload }) => {
      state.datamangerPagination = {
        ...state.datamangerPagination,
        current: payload.current,
        pageSize: payload.pageSize,
        sortBy: payload.order === 1 ? "ASC" : "DESC",
        search: payload.search,
        orderBy: payload.orderBy,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFieldsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFieldsList.fulfilled, (state, { payload }) => {
        state.FieldsList = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.datamangerPagination = {
          ...state.datamangerPagination,
          total: payload.total,
        };
      })
      .addCase(getFieldsList.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(CreateandUpdateDataField.pending, (state) => {
        state.isFieldCreating = true;
      })
      .addCase(CreateandUpdateDataField.fulfilled, (state, { payload }) => {
        state.iscreateFieldDrawerVisible = false;
        state.isFieldCreating = false;
      })
      .addCase(CreateandUpdateDataField.rejected, (state, { payload }) => {
        state.isFieldCreating = false;
      });
  },
});
export const {
  setCreateFieldDrawerVisible,
  setModalVisible,
  onSearchList,
  onPageChangesFilter,
} = DatamanagerSlice.actions;

export default DatamanagerSlice.reducer;
