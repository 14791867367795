import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/historicalContractSetting";
const localurl = "/api/v1";
class stffService {
  getHistoricalListAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/historical_list`, data, headers);
  createHistoricalContract = (data) =>
    RestfulProvider.formDatapost(
      `${subUrllocal}/historical_create`,
      data,
      headers
    );
  deleteTaskApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/historical_delete`, data, headers);
  ResetpasswordApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateUserProfile`, data, headers);
  getHistoricaljsondataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getforms`, data, headers);
  updateHistoricaljsondataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateDefaultFields`, data, headers);
  CreatehistoricalContractsAPI = (data) =>
    RestfulProvider.formDatapost(`${subUrllocal}/submit`, data, headers);
  GetbufferdatafromUrlAPI = (data) =>
    RestfulProvider.downloadMethod(`${localurl}/content/getpdf`, data, headers);
  CreateBulkHistoricalAPI = (data) =>
    RestfulProvider.formDatapost(`${subUrllocal}/bulkpdfupload`, data, headers);
  getExcelforBulkHistoricalAPI = (data) =>
    RestfulProvider.downloadMethod(`${subUrllocal}/exportExcel`, data, headers);
  getImportsListAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/historicalInstance`, data, headers);
  getImportItemByIdAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/historicalInstanceData`, data, headers);
  submitFileUploadExcelAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/bulkUpload`, data, headers);
  getconnectwithGoogledriveAPI= (data) =>
    RestfulProvider.get(`${subUrllocal}/google/:${data?.companyId}`, data, headers);
  getTokenforGoogledriveAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/getToken`, data, headers);
  SendfilesandfolderAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/sendfilesandfolders`, data, headers);
}
// eslint-disable-next-line
export default new stffService();

// getImportsListAPI = (data) =>
//   RestfulProvider.post(`${subUrllocal}/getBulkImportList`, data, headers);
// getImportItemByIdAPI= (data) =>
//   RestfulProvider.post(`${subUrllocal}/getContractsByGroup`, data, headers);