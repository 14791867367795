import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/contentType";
const subUrllocal2 = "/api/v1/sampleContent";
const subUrllocal3 = "/api/v1/adobe";
const subUrllocal4 = "/api/v1/docusign";
const subUrllocal5 = "/api/v1/clause";
class contentService {
  getContenttypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/contentTypes`, data, headers);
  createContenttypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);

  deletecontenttypeApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  getSampleContentAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/contents`, data, headers);
  createandupdateSampleContentAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/create`, data, headers);
  deleteSampleContentApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal2}/delete`, data, headers);
  importCategoryfromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/import`, data, headers);
  deleteadmincontenttypeApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/remove`, data, headers);
  importsamplecontentfromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/import`, data, headers);
  deleteadminsamplecontentApi = (data) =>
    RestfulProvider.post(`${subUrllocal2}/remove`, data, headers);
  connecttoadobeApi = (data) =>
    RestfulProvider.post(`${subUrllocal3}/connect`, data, headers);
  connecttodocuSignApi = (data) =>
    RestfulProvider.post(`${subUrllocal4}/connectDocuSign`, data, headers);
  SetConnectwithAdobeApi = (data) =>
    RestfulProvider.get(`${subUrllocal3}/addAdobeAccount`, data, headers);
  SetConnectwithDocusignApi = (data) =>
    RestfulProvider.get(`${subUrllocal4}/addDocuAccount`, data, headers);
  SetdisconnectwithdocusignApi = (data) =>
    RestfulProvider.post(`${subUrllocal4}/remove`, data, headers);
  SetdisconnectwithAdobeApi = (data) =>
    RestfulProvider.get(`${subUrllocal3}/remove`, data, headers);
  getClausesListAPI = (data) =>
    RestfulProvider.post(`${subUrllocal5}`, data, headers);
  createAndUpdateClauseAPI = (data) =>
    RestfulProvider.post(`${subUrllocal5}/create`, data, headers);
  deleteClauseApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal5}/delete`, data, headers);
}
// eslint-disable-next-line
export default new contentService();
