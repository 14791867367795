import { createSlice } from "@reduxjs/toolkit";
import {
  getTaskdetails,
  historicalFlowCreate,
  updatehistoricalFlow,
  Resetpassword,
  deleteTask,
  getHistoricaljsondata,
  updateHistoricaljsondefaultdata,
  CreatehistoricalContracts,
  GetbufferdatafromUrl,
  CreatehistoricalContractsAddendum,
  getHistoricalDatabyId,
  getImportsList,
  getImportItemById,
  CreateBulkHistorical,
  submitFileUploadExcel,
  getTokenforGoogledrive,
  Sendfilesandfolder,
} from "./historicalFlowAction";
import { message } from "antd";
const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  historicalTaskList: [],
  isVisible: false,
  otherFieldOfChecklist: [],
  isDrawerVisible: false,
  ispasswordVisible: false,
  ispasswordloading: false,
  ispasswordupdated: false,
  editRecord: {},
  type: "",
  ispagination: false,
  totalCount: null,
  historicalTaskPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  historicalTaskPaginationbygroupById: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  otherChecklistData: [],
  selectedOtherField: {},
  isEditjsonModalVisible: false,
  EditjsonModalRecord: {},
  EditjsonModaltype: "",

  historicalformdata: [],
  isnewfieldModalVisible: false,
  collapseDataIds: 0,
  fieldcreatefrom: "",
  Companydetails: "",
  EditcollapseIds: 0,
  Templateselectedval: {},
  jsonforvariables: [],
  templateforjsonData: {},
  Generalextradetails: [],
  otherChecklistextradetailData: {},
  isFilterApplied: false,
  isFiltermodalopen: false,
  contractpdfurl: "",
  isHistoricalDatinlimit: {},
  importtype: "",
  isAddendumDrawerVisible: false,
  isHistoricalDetailViewVisible: false,
  extraRecord: {},
  isBulkLegacyImportVisible: false,
  isMetadataInfoModalVisible: false,
  ImportLists: [],
  ImportListsLoading: true,
  groupIdInfo: {},
  isCheckBulkUploadModalVisible: false,
  BulkuploadRecord: {},
  isBulkpdupLoading: false,
  isBulkjsonupLoading: false,
  ImportListPagiantion: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  googleDriveData: {},
};
const flowSlice = createSlice({
  name: "flow",
  initialState: initialState,
  reducers: {
    onChangeOtherData: (state, { payload }) => {
      state.otherChecklistextradetailData = payload?.record || {};
      state.otherChecklistData =
        payload?.record?.checkListData?.length > 0
          ? payload.record.checkListData
          : [];
    },
    onClickAddOtherField: (state, { payload }) => {
      state.selectedOtherField = payload.claItem;
      var updatedFields = [...state.otherFieldOfChecklist];
      updatedFields.push({
        _id: payload.claItem._id,
      });
      state.otherFieldOfChecklist = updatedFields;
    },
    onChangeNewvalueTitle: (state, { payload }) => {
      const updatedFields = [...state.otherFieldOfChecklist];
      const addOfValueIndex = updatedFields.findIndex(
        (ofItem) => ofItem._id === payload.claItem._id
      );
      updatedFields[addOfValueIndex] = {
        ...updatedFields[addOfValueIndex],
        value: payload.value,
      };
      state.otherFieldOfChecklist = updatedFields;
    },
    onChangeNewKeyTitle: (state, { payload }) => {
      const updatedFields = [...state.otherFieldOfChecklist];
      const addOfkeyIndex = updatedFields.findIndex(
        (ofItem) => ofItem._id === payload.claItem._id
      );
      updatedFields[addOfkeyIndex] = {
        ...updatedFields[addOfkeyIndex],
        label: payload.key,
      };
      state.otherFieldOfChecklist = updatedFields;
    },
    onClickAddnewOtherFieldInObject: (state, { payload }) => {
      if (!payload.onfItem.label && !payload.onfItem.value) {
        message.warn("Please enter label and its value");
      }
      if (!payload.onfItem.label && payload.onfItem.value) {
        message.warn("Please enter label.");
      }
      if (!payload.onfItem.value && payload.onfItem.label) {
        message.warn("Please enter value.");
      }
      if (payload.onfItem.label && payload.onfItem.value) {
        const getSelectedIndex = state.otherChecklistData.findIndex(
          (findItem) => findItem._id === payload.claItem._id
        );
        const updatedFields = [
          ...state.otherChecklistData[getSelectedIndex].checkListData,
        ];
        updatedFields.push({
          otherField: true,
          ...payload.onfItem,
          dataType: 2,
          _id: Math.random().toString(),
        });
        const updatedotherChecklistData = [...state.otherChecklistData];
        updatedotherChecklistData[getSelectedIndex] = {
          ...updatedotherChecklistData[getSelectedIndex],
          checkListData: updatedFields,
        };

        return {
          ...state,
          otherChecklistData: updatedotherChecklistData,
          otherFieldOfChecklist: [],
        };
      }
    },
    onClicRemoveOtherField: (state, { payload }) => {
      return {
        ...state,
        otherFieldOfChecklist: [],
      };
    },

    valueaddedonChange: (state, { payload }) => {
      const { extraindex, value, contractType, formId } = payload;

      const updatedFields = [...state.otherChecklistData];
      updatedFields[extraindex] = {
        ...updatedFields[extraindex],
        value,
        contractType,
        form: "extra",
        formId: formId,
      };

      return {
        ...state,
        otherChecklistData: updatedFields,
      };
    },
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.importtype = payload?.type || "";
      state.editRecord = payload.record || {};
      state.extraRecord = payload.extraRecord || {};

      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    setAddendumDrawerVisible: (state, { payload }) => {
      state.isAddendumDrawerVisible = payload.visible;
      state.editRecord = payload.record || {};

      if (payload.type === "edit" || payload.type === "view") {
        if (payload?.record?.formWithValue?.length > 0) {
          const extradata = payload?.record?.formWithValue[0]?.fields.filter(
            (item) => item?.form === "extra"
          );
          const jsondata = payload?.record?.formWithValue[0]?.fields.filter(
            (item) => item?.form !== "extra"
          );
          state.otherChecklistData = extradata.length > 0 ? extradata : [];
          state.jsonforvariables = jsondata.length > 0 ? jsondata : [];
        }

        state.templateforjsonData =
          payload?.record?.formWithValue?.length > 0
            ? payload?.record?.formWithValue[0]
            : {};
        state.Generalextradetails = payload?.record?.parties?.extradetail || [];
      }
      state.type = payload.type || "";
      if (!payload.visible) {
        state.isVisible = false;
        state.editRecord = {};

        state.collapseDataIds = 0;
        state.historicalformdata = [];
        state.jsonforvariables = [];
        state.templateforjsonData = {};
        state.Generalextradetails = [];
        state.otherChecklistData = [];
        state.contractpdfurl = "";
        state.isHistoricalDetailViewVisible = false;
        // state.otherFieldOfChecklist = [];
      }
    },
    setPasswordmodal: (state, { payload }) => {
      state.ispasswordVisible = payload.visible;
      state.editRecord = payload?.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.historicalTaskPagination = {
        ...state.historicalTaskPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onPageChangesFilterforGroupbyId: (state, action) => {
      state.historicalTaskPaginationbygroupById = {
        ...state.historicalTaskPaginationbygroupById,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.historicalTaskPagination = {
        ...state.historicalTaskPagination,
        search: action.payload.search || "",
      };
    },
    flowdatareset: (state, action) => {
      return initialState;
    },
    setDrawerVisible: (state, { payload }) => {
      state.isDrawerVisible = payload.visible;
      state.editRecord = payload.record || {};

      if (payload.type === "edit" || payload.type === "view") {
        if (payload?.record?.formWithValue?.length > 0) {
          const extradata = payload?.record?.formWithValue[0]?.fields.filter(
            (item) => item?.form === "extra"
          );
          const jsondata = payload?.record?.formWithValue[0]?.fields.filter(
            (item) => item?.form !== "extra"
          );

          state.otherChecklistData = extradata.length > 0 ? extradata : [];
          state.jsonforvariables = jsondata.length > 0 ? jsondata : [];
        }

        state.templateforjsonData =
          payload?.record?.formWithValue?.length > 0
            ? payload?.record?.formWithValue[0]
            : {};
        state.Generalextradetails = payload?.record?.parties?.extradetail || [];
      }
      state.type = payload.type || "";
      if (!payload.visible) {
        state.isVisible = false;
        state.editRecord = {};

        state.collapseDataIds = 0;
        state.historicalformdata = [];
        state.jsonforvariables = [];
        state.templateforjsonData = {};
        state.Generalextradetails = [];
        state.otherChecklistData = [];
        state.contractpdfurl = "";
        // state.otherFieldOfChecklist = [];
      }
    },
    setEditjsonModal: (state, { payload }) => {
      state.isEditjsonModalVisible = payload.visible;
      state.EditjsonModalRecord = payload.record || {};
      state.EditjsonModaltype = payload.type || "";
      if (!payload.visible) {
        state.EditcollapseIds = 0;

        // state.isVisible = false;
        // state.editRecord = {};
        // state.otherChecklistData = [];
        // state.otherFieldOfChecklist = [];
      }
    },
    changehistoricalLabelval: (state, { payload }) => {
      const { templateindex, value } = payload;
      const updatedFields = [...state.jsonforvariables];
      updatedFields[templateindex] = {
        ...updatedFields[templateindex],
        label: value,
      };

      return {
        ...state,
        jsonforvariables: updatedFields,
      };
    },
    changehistoricalGeneralLabelval: (state, { payload }) => {
      const { templateindex, value } = payload;
      const updatedFields = [...state.Generalextradetails];
      updatedFields[templateindex] = {
        ...updatedFields[templateindex],
        label: value,
      };

      return {
        ...state,
        Generalextradetails: updatedFields,
      };
    },
    changehistoricalExtraLabelval: (state, { payload }) => {
      const { templateindex, value } = payload;
      const updatedFields = [...state.otherChecklistData];
      updatedFields[templateindex] = {
        ...updatedFields[templateindex],
        label: value,
      };

      return {
        ...state,
        otherChecklistData: updatedFields,
      };
    },
    formjsonvalueaddedonChange: (state, { payload }) => {
      const { parentindex, childindex, value, subvalue } = payload;
      const updatedFields = [
        ...state.historicalformdata[parentindex].historicalTemplateFields,
      ];
      updatedFields[childindex] = {
        ...updatedFields[childindex],
        value,
        subvalue,
      };
      const updatedhistoricalformdata = [...state.historicalformdata];
      updatedhistoricalformdata[parentindex] = {
        ...updatedhistoricalformdata[parentindex],
        historicalTemplateFields: updatedFields,
      };
      return {
        ...state,
        historicalformdata: updatedhistoricalformdata,
      };
    },
    deletefieldfromjson: (state, { payload }) => {
      const { templateindex } = payload;
      state.jsonforvariables.splice(templateindex, 1);
    },
    deleteextrafielditemfromjson: (state, { payload }) => {
      const { parentindex, childindex } = payload;
      state.otherChecklistData[parentindex].checkListData.splice(childindex, 1);
    },
    Setnewfieldmodalvisible: (state, { payload }) => {
      state.isnewfieldModalVisible = payload.visible;
      state.fieldcreatefrom = payload?.createfrom || "";

      if (!payload.visible) {
        // state.isVisible = false;
        // state.editRecord = {};
        // state.otherChecklistData = [];
        // state.otherFieldOfChecklist = [];
      }
    },
    submitnewFielddata: (state, { payload }) => {
      if (state.fieldcreatefrom === "General") {
        state.Generalextradetails.push(...payload);
      } else if (state.fieldcreatefrom === "Extra") {
        state.otherChecklistData.push(...payload);
      } else {
        state.jsonforvariables.push(...payload);
      }
      state.isnewfieldModalVisible = false;
      //  state.otherChecklistData=[{...state.otherChecklistData,checkListData:...state.otherChecklistData.checkListData}]
      if (!payload.visible) {
        // state.isVisible = false;
        // state.editRecord = {};
        // state.otherChecklistData = [];
        // state.otherFieldOfChecklist = [];
      }
    },
    onChangehiscollapsePanel: (state, { payload }) => {
      state.collapseDataIds = payload;
    },
    setCompanydetails: (state, { payload }) => {
      state.Companydetails = payload;
    },
    onChangehisEditcollapseIdsPanel: (state, { payload }) => {
      state.EditcollapseIds = payload;
    },
    setTemplateselectedval: (state, { payload }) => {
      state.Templateselectedval = payload;
    },
    onTemplatevaluechanges: (state, { payload }) => {
      const { templateindex, value, contractType, UniqueId } = payload;
      const actualIndex = state.jsonforvariables.findIndex(
        (obj) => obj._id === UniqueId
      );
      const updatedFields = [...state.jsonforvariables];
      updatedFields[actualIndex] = {
        ...updatedFields[actualIndex],
        value,
        contractType,
      };

      return {
        ...state,
        jsonforvariables: updatedFields,
      };
    },
    onGeneralTemplatevaluechanges: (state, { payload }) => {
      const { templateindex, value, contractType } = payload;
      const updatedFields = [...state.Generalextradetails];
      updatedFields[templateindex] = {
        ...updatedFields[templateindex],
        value,
        contractType,
      };

      return {
        ...state,
        Generalextradetails: updatedFields,
      };
    },
    deletefieldfromGeneraljson: (state, { payload }) => {
      const { templateindex } = payload;
      state.Generalextradetails.splice(templateindex, 1);
    },
    deletefieldfromExtrajson: (state, { payload }) => {
      const { extraindex } = payload;
      state.otherChecklistData.splice(extraindex, 1);
    },
    getHistoricalfilterActive: (state, { payload }) => {
      state.isFilterApplied = payload;
    },
    filterpopoverhandleOpenChange: (state, { payload }) => {
      state.isFiltermodalopen = payload;
    },
    filterchangepagination: (state, { payload }) => {
      if (Object.keys(payload).length === 0) {
        state.historicalTaskPagination = {
          ...initialState.historicalTaskPagination,
        };
      } else {
        state.historicalTaskPagination = {
          ...state.historicalTaskPagination,
          ...payload,
        };
      }
    },
    makelifecyclefieldrequiredvalChange: (state, { payload }) => {
      const { lifecycleFieldIndex, required, value } = payload;
      const updatedFields = [...state.jsonforvariables];
      updatedFields[lifecycleFieldIndex] = {
        ...updatedFields[lifecycleFieldIndex],

        required: required,
        value: value ? value : updatedFields[lifecycleFieldIndex].value,
      };

      return {
        ...state,
        jsonforvariables: updatedFields,
      };
      // state.jsonforvariables.splice(templateindex, 1);
    },
    setcontractpdfurl: (state, { payload }) => {
      state.contractpdfurl = "";
    },
    setHistoricalDetailViewVisible: (state, { payload }) => {
      state.isHistoricalDetailViewVisible = payload?.visible;
      state.editRecord = payload?.record;
    },
    setbulkLegacyImportVisible: (state, { payload }) => {
      state.isBulkLegacyImportVisible = payload.visible;
    },
    setMetadataInfoModalVisible: (state, { payload }) => {
      state.isMetadataInfoModalVisible = payload.visible;
    },
    setImportInfobygroupId: (state, { payload }) => {
      state.groupIdInfo = payload;
    },
    setCheckBulkUploadModalVisible: (state, { payload }) => {
      state.isCheckBulkUploadModalVisible = payload?.visible;
      state.BulkuploadRecord = payload?.record || {};
    },
    onPageChangesFilterofImport: (state, { payload }) => {
      state.ImportListPagiantion = {
        ...state.ImportListPagiantion,
        current: payload.current,
        pageSize: payload.size,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskdetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaskdetails.fulfilled, (state, { payload }) => {
        state.historicalTaskList = payload.data;
        state.isHistoricalDatinlimit =
          payload?.licenseQuota?.historicalContract;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.historicalTaskPagination = {
          ...state.historicalTaskPagination,
          total: payload.total,
        };
        // state.groupIdInfo = {};
      })
      .addCase(getTaskdetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(historicalFlowCreate.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(historicalFlowCreate.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;

        state.otherChecklistData = [];

        state.collapseDataIds = 0;
        state.historicalformdata = [];
        state.editRecord = {};
        state.isDrawerVisible = false;
        state.isVisible = false;
        state.Generalextradetails = [];
        // responseMessageHandler()
      })
      .addCase(historicalFlowCreate.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(updatehistoricalFlow.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatehistoricalFlow.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;

        // responseMessageHandler()
      })
      .addCase(updatehistoricalFlow.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteTask.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deleteTask.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(Resetpassword.pending, (state, { payload }) => {
        state.ispasswordloading = true;
      })
      .addCase(Resetpassword.fulfilled, (state, { payload }) => {
        state.ispasswordVisible = false;
        state.ispasswordloading = false;
        state.ispasswordupdated = true;
      })
      .addCase(Resetpassword.rejected, (state, { payload }) => {
        state.ispasswordloading = false;
      })
      .addCase(getHistoricaljsondata.pending, (state, { payload }) => {
        // state.ispasswordloading = true;
      })
      .addCase(getHistoricaljsondata.fulfilled, (state, { payload }) => {
        state.jsonforvariables = payload?.data || [];
        state.templateforjsonData =
          payload?.data?.length > 0 ? { fields: payload?.data } : {};
      })
      .addCase(getHistoricaljsondata.rejected, (state, { payload }) => {
        // state.ispasswordloading = false;
      })
      .addCase(
        updateHistoricaljsondefaultdata.pending,
        (state, { payload }) => {
          // state.ispasswordloading = true;
        }
      )
      .addCase(
        updateHistoricaljsondefaultdata.fulfilled,
        (state, { payload }) => {
          state.isEditjsonModalVisible = false;
        }
      )
      .addCase(
        updateHistoricaljsondefaultdata.rejected,
        (state, { payload }) => {
          // state.ispasswordloading = false;
        }
      )
      .addCase(CreatehistoricalContracts.pending, (state, { payload }) => {
        state.isCreateLoading = true;
      })
      .addCase(CreatehistoricalContracts.fulfilled, (state, { payload }) => {
        state.jsonforvariables = [];
        state.templateforjsonData = {};
        state.isDrawerVisible = false;
        state.isVisible = false;
        state.isCreateLoading = false;
        state.Generalextradetails = [];
        state.otherChecklistData = [];
        state.collapseDataIds = 0;
      })
      .addCase(CreatehistoricalContracts.rejected, (state, { payload }) => {
        // state.ispasswordloading = false;
        state.isCreateLoading = false;
      })
      .addCase(GetbufferdatafromUrl.pending, (state, { payload }) => {
        state.contractpdfurl = "";
      })
      .addCase(GetbufferdatafromUrl.fulfilled, (state, { payload }) => {
        state.contractpdfurl = payload;
      })
      .addCase(GetbufferdatafromUrl.rejected, (state, { payload }) => {
        state.contractpdfurl = "";
      })
      .addCase(
        CreatehistoricalContractsAddendum.pending,
        (state, { payload }) => {
          state.isCreateLoading = true;
        }
      )
      .addCase(
        CreatehistoricalContractsAddendum.fulfilled,
        (state, { payload }) => {
          state.jsonforvariables = [];
          state.templateforjsonData = {};
          state.isAddendumDrawerVisible = false;
          state.isVisible = false;
          state.isCreateLoading = false;
          state.Generalextradetails = [];
          state.otherChecklistData = [];
          state.collapseDataIds = 0;
          if (state.type === "edit") {
            state.editRecord = payload?.data;
          }
        }
      )
      .addCase(
        CreatehistoricalContractsAddendum.rejected,
        (state, { payload }) => {
          // state.ispasswordloading = false;
          state.isCreateLoading = false;
        }
      )
      .addCase(getHistoricalDatabyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHistoricalDatabyId.fulfilled, (state, { payload }) => {
        // state.isAddendumDrawerVisible = true;
        // state.type = "edit";
        state.isLoading = false;

        state.editRecord = payload?.data?.length > 0 ? payload.data[0] : {};
      })
      .addCase(getHistoricalDatabyId.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getImportsList.pending, (state) => {
        state.ImportListsLoading = false;
      })
      .addCase(getImportsList.fulfilled, (state, { payload }) => {
        state.ImportListsLoading = true;
        state.ImportLists = payload?.data;
        state.ImportListPagiantion = {
          ...state.ImportListPagiantion,
          total: payload?.total,
        };
      })
      .addCase(getImportsList.rejected, (state, { payload }) => {
        state.ImportListsLoading = false;
      })
      .addCase(getImportItemById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getImportItemById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.historicalTaskList = payload?.data;
        // state.totalCount = payload.data?.length;
        state.historicalTaskPaginationbygroupById = {
          ...state.historicalTaskPaginationbygroupById,
          total: payload?.total,
        };
      })
      .addCase(getImportItemById.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(CreateBulkHistorical.pending, (state) => {
        state.isBulkpdupLoading = true;
      })
      .addCase(CreateBulkHistorical.fulfilled, (state, { payload }) => {
        state.isBulkpdupLoading = false;
      })
      .addCase(CreateBulkHistorical.rejected, (state, { payload }) => {
        state.isBulkpdupLoading = false;
      })
      .addCase(submitFileUploadExcel.pending, (state) => {
        state.isBulkjsonupLoading = true;
      })
      .addCase(submitFileUploadExcel.fulfilled, (state, { payload }) => {
        state.isBulkjsonupLoading = false;
        state.isCheckBulkUploadModalVisible = false;
        state.isMetadataInfoModalVisible = false;
      })
      .addCase(submitFileUploadExcel.rejected, (state, { payload }) => {
        state.isBulkjsonupLoading = false;
      })
      .addCase(getTokenforGoogledrive.pending, (state) => {})
      .addCase(getTokenforGoogledrive.fulfilled, (state, { payload }) => {
        state.googleDriveData = payload?.data;
      })
      .addCase(getTokenforGoogledrive.rejected, (state, { payload }) => {})
      .addCase(Sendfilesandfolder.pending, (state) => {
        state.isBulkpdupLoading = true;
      })
      .addCase(Sendfilesandfolder.fulfilled, (state, { payload }) => {
        state.isBulkLegacyImportVisible = false;
        state.googleDriveData = {};
        state.isBulkpdupLoading = false;
      })
      .addCase(Sendfilesandfolder.rejected, (state, { payload }) => {
        state.isBulkpdupLoading = false;
      });
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  flowdatareset,
  onSearchList,
  setPasswordmodal,
  setDrawerVisible,
  onChangeOtherData,
  valueaddedonChange,
  onClickAddOtherField,

  onChangeNewKeyTitle,
  onChangeNewvalueTitle,
  onClickAddnewOtherFieldInObject,
  onClicRemoveOtherField,
  setEditjsonModal,
  changehistoricalLabelval,
  formjsonvalueaddedonChange,
  Setnewfieldmodalvisible,
  submitnewFielddata,
  onChangehiscollapsePanel,
  setCompanydetails,
  deletefieldfromjson,
  onChangehisEditcollapseIdsPanel,
  deleteextrafielditemfromjson,
  setTemplateselectedval,
  onTemplatevaluechanges,
  onGeneralTemplatevaluechanges,
  deletefieldfromGeneraljson,
  deletefieldfromExtrajson,
  changehistoricalExtraLabelval,
  changehistoricalGeneralLabelval,
  getHistoricalfilterActive,
  filterpopoverhandleOpenChange,
  filterchangepagination,
  makelifecyclefieldrequiredvalChange,
  setcontractpdfurl,
  setAddendumDrawerVisible,
  setHistoricalDetailViewVisible,
  setbulkLegacyImportVisible,
  setMetadataInfoModalVisible,
  setImportInfobygroupId,
  setCheckBulkUploadModalVisible,
  onPageChangesFilterforGroupbyId,
  onPageChangesFilterofImport,
} = flowSlice.actions;

export default flowSlice.reducer;
